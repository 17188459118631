
import { Link } from "react-router-dom";
import { Button } from 'react-bulma-components';
import './App.css';

const App = () => {
  return (
    <div className="app" style={{ backgroundImage: "url(/mainscreenbg.jpeg)" }}>
      <div className="logo-text">Learnia</div>
      <div className="actions">
        <p className="mb-4" style={{color: '#555'}}>Best viewed on a tablet or laptop.<br/>Phone not recommended.</p>
        <Button color="primary" className="is-rounded mb-8"><Link to={`stories`}>Reading</Link></Button>
        <Button color="primary" className="is-rounded"><Link to={`math`}>Math</Link></Button>
        {/* <Button color="primary" renderAs="a"><Link to={`math/add`}>Addition</Link></Button>
        <Button color="primary" renderAs="a"><Link to={`math/subtract`}>Subtraction</Link></Button>
        <Button color="primary" renderAs="a"><Link to={`math/multiply`}>Multiplication</Link></Button>
        <Button color="primary" renderAs="a"><Link to={`math/bingo/add`}>Bingo Add</Link></Button>
        <Button color="primary" renderAs="a"><Link to={`math/bingo/subtract`}>Bingo Subtract</Link></Button> */}
        {/* <Button color="warning" onClick={() => handleStartGame(2)}>Level 2</Button>
        <Button color="danger" onClick={() => handleStartGame(3)}>Level 3</Button> */}
      </div>
      
      {/* <MathApp /> */}
      {/* <FillInBlanksApp /> */}
    </div>
  );
}

export default App