
import { Link } from "react-router-dom";
import { Button } from 'react-bulma-components';
// import './App.css';
import NavbarActions from './NavbarActions.js';

const MathApp = () => {
  return (
    <div className="app" style={{ backgroundImage: "url(/bg.jpeg)" }}>
      <NavbarActions />
      <div className="actions">
        <Button color="primary" className="is-rounded" renderAs="a"><Link to={`/math/add`}>Addition</Link></Button>
        <Button color="primary" className="is-rounded" renderAs="a"><Link to={`/math/subtract`}>Subtraction</Link></Button>
        <Button color="primary" className="is-rounded" renderAs="a"><Link to={`/math/multiply`}>Multiplication</Link></Button>
        <Button color="primary" className="is-rounded" renderAs="a"><Link to={`/math/bingo/add`}>Bingo Add</Link></Button>
        <Button color="primary" className="is-rounded" renderAs="a"><Link to={`/math/bingo/subtract`}>Bingo Subtract</Link></Button>
        {/* <Button color="warning" onClick={() => handleStartGame(2)}>Level 2</Button>
        <Button color="danger" onClick={() => handleStartGame(3)}>Level 3</Button> */}
      </div>
      
      {/* <MathApp /> */}
      {/* <FillInBlanksApp /> */}
    </div>
  );
}

export default MathApp