import React, { useState, useCallback, useEffect } from 'react'
import { Button, Container, Section, Columns, Progress } from 'react-bulma-components';
import update from 'immutability-helper'
import { useNavigate } from "react-router-dom";
import { generateMathQuestion, speakOutLoud, positiveNotificationMsg, ARITHMETIC_MODES_CONFIG, NOTIFICATION_DURATION_IN_SECONDS } from './utils.js'
import InGameNotification from './InGameNotification.js';
import NavbarActions from './NavbarActions.js';
import Confetti from 'react-confetti'
import useWindowSize from './hooks/useWindowSize.js'
import './StoriesApp.css';
import data from './stories.json';

export default function SimpleArithmeticApp({mode}) {
  const expansionMessage = {
    author: 'bot',
    content: 'What would you like to do next?',
    continuations: [
      {title:'❓ Quiz Me', type:'expansion', query: 'quiz'},
      // {title:'Why is this important?', type:'expansion', query: 'why-important'},
      {title:'📺 Watch Videos', type:'expansion', query: 'videos'},
      {title:'🔃 Learn something else', type:'expansion', query: 'new-topic'},
    ]
  }

  let chooseTopicMessage = {
    author: 'bot',
    content: 'What would you like to learn about?',
    continuations: [
      {title:'📖 History', type:'topic', query: 'history'},
      {title:'🌲 Nature', type:'topic', query: 'nature'},
      {title:'🧪 Science', type:'topic', query: 'science'},
      {title:'🤴 Famous People', type:'topic', query: 'famous-people'},
      // {title:'Music', type:'topic', query: 'music'},
      // {title:'Art', type:'topic', query: 'art'},
    ],
  }
  const [messages, setMessages] = useState([chooseTopicMessage])
  
  const [currentTopicData, setCurrentTopicData] = useState()
  const [currentQuizQuestionIndex, setCurrentQuizQuestionIndex] = useState(0)
  const [showVideos, setShowVideos] = useState(false)
  const [completedTyping, setCompletedTyping] = useState(false)
  const [displayResponse, setDisplayResponse] = useState('')

  useEffect(() => {
    if (messages[messages.length - 1].isBeingTypewritten) {
      setCompletedTyping(false);
      
      let i = 0;
      const stringResponse = messages[messages.length-1].content
      
      const intervalId = setInterval(() => {
        setDisplayResponse(stringResponse.slice(0, i));
        i = i + 10;
        if (i > stringResponse.length) {
          clearInterval(intervalId);
          setCompletedTyping(true);
          let messageIndexToUpdate = messages.length - 1
          let msgs = update(messages, {[messageIndexToUpdate]: { $unset: ['isBeingTypewritten'] }})
          msgs = msgs.concat([expansionMessage])
          setMessages(msgs)
        }
      }, 60);
      
      return () => clearInterval(intervalId);
    } else if (messages.length >= 2 && messages[messages.length - 2].isMainTopic) {
    } else if (messages.length === 1) {
      window.scrollTo(0, 0)
    } else {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [messages]);
  
  const onButtonClicked = useCallback((continuation) => {
    let lastMessage = messages[messages.length - 1]
    continuation.disabled = true
    lastMessage.continuations = [continuation]
    lastMessage.author = 'user'
    
    let msgs = messages
    if (continuation.type === 'topic') {
      msgs = continueWithTopic(continuation.query)
      msgs[msgs.length - 2] = lastMessage
    } else if (continuation.type === 'expansion' && continuation.query === 'quiz') {
      msgs = continueWithQuiz(currentQuizQuestionIndex)
      msgs[msgs.length - 2] = lastMessage
    } else if (continuation.type === 'expansion' && continuation.query === 'videos') {
      msgs = continueWithVideos()
      msgs[msgs.length - 3] = lastMessage
    } else if (continuation.type === 'expansion' && continuation.query === 'new-topic') {
      msgs = continueWithNewTopicChoice()
    }
    
    setMessages(msgs)
  }, [messages])
  
  const onQuizAnswerButtonClicked = useCallback((option) => {
    let messageIndexToUpdate = messages.length - 1
    let msgs = update(messages, {[messageIndexToUpdate]: { chosenAnswer: {$set: option.query}}})
    setMessages(msgs)
    if (option.query === msgs[messageIndexToUpdate].answer) {
      speakOutLoud("That's correct, great job!")
      setCurrentQuizQuestionIndex(currentQuizQuestionIndex + 1)
      continueWithQuiz(currentQuizQuestionIndex + 1, msgs)
    } else {
      speakOutLoud("Try again")
    }
  }, [messages, currentQuizQuestionIndex])
  // =======================================================
  
  const continueWithTopic = useCallback((topic) => {
    const randomIndex = Math.floor(Math.random() * data[topic].length);
    const randomStory = data[topic][randomIndex]
    setCurrentTopicData(randomStory)
    let msgs = messages.concat([{
      author: 'bot',
      content: randomStory.content,
      isMainTopic: true,
      isBeingTypewritten: true
    // }, expansionMessage])
    }])
    setMessages(msgs)
    return msgs
  }, [messages])

  const continueWithVideos = useCallback(() => {
    setShowVideos(true)
    let msgs = messages.concat([{
      author: 'bot',
      content: '',
      videos: currentTopicData.videos,
    }, expansionMessage])
    setMessages(msgs)
    return msgs
  }, [messages, currentTopicData])

  const continueWithNewTopicChoice = useCallback(() => {
    setCurrentTopicData()
    setCurrentQuizQuestionIndex(0)
    setShowVideos(false)
    setDisplayResponse('')
    let msgs = [chooseTopicMessage]
    setMessages(msgs)
    return msgs
  }, [messages])

  const continueWithQuiz = useCallback((quizQuestionIndex, msgs) => {
    msgs = msgs || messages
    if (quizQuestionIndex < currentTopicData.quiz.length) {
      msgs = msgs.concat([{
        author: 'bot',
        content: currentTopicData.quiz[quizQuestionIndex].question,
        answerOptions: currentTopicData.quiz[quizQuestionIndex].answerOptions,
        answer: currentTopicData.quiz[quizQuestionIndex].answer,
      }])
    } else {
      msgs = msgs.concat([expansionMessage])
    }
    setMessages(msgs)
    return msgs  
  }, [currentTopicData, messages])

  // =======================================================
  const ContinuationOptions = ({options}) => {
    return (
      <div className="continuations">
        {options.length > 0 && options.map((item) => {
          if (item.query === 'quiz' && (!currentTopicData.quiz || (currentTopicData.quiz && currentTopicData.quiz.length > 0 && currentQuizQuestionIndex >= currentTopicData.quiz.length))) return ''
          if (item.query === 'videos' && (!currentTopicData.videos || showVideos)) return ''
          return (
            <Button className={item.disabled ? 'is-light is-rounded is-static mb-4' : 'is-info is-light is-rounded mb-4'} onClick={() => onButtonClicked(item)}>{item.title}</Button>
            )
          })}
      </div>
    )
  }
  
  const QuizOptions = ({options, chosenAnswer, isCorrect}) => {
    return (
      <div className="quiz-options is-flex is-flex-direction-column">
        {options.length > 0 && options.map((item) => {
          return (
            <Button className={isCorrect === true ? 'is-light is-rounded is-static mt-4' : 'is-link is-light is-rounded mt-4'} onClick={() => onQuizAnswerButtonClicked(item)}>{item.title}</Button>
            )
        })}
        {chosenAnswer ? (
          <article className={isCorrect === true ? "message is-success mt-2" : "message is-warning mt-2"}>
            <div className="message-body">{isCorrect ? positiveNotificationMsg("That's correct, great job!") : "Try again"}</div>
          </article>
        ) : ''}
      </div>
    )
  }

  const YouTubeVideo = ({url}) => {
    const id = url.match((new RegExp(/v=(.\w*)&?/)))
    if (id.length > 0) {
      return (
        <iframe className="is-flex is-align-content-center is-align-items-center is-align-self-center" width="560" height="315" src={`https://www.youtube.com/embed/${id[1]}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      )
    } else {
      <article className="message is-danger mt-2">
        <div className="message-body">Sorry, couldn't load the video.</div>
      </article>
    }
  }
  
  const StorySection = ({msg}) => {
    return (
      <>
      <Columns>
        <Columns.Column size="1" className={msg.author === 'bot' ? '' : 'is-invisible'}>
          <img src="/ai-avatar.png" />
          {/* <div className='avatar avatar-bot'>AI</div> */}
        </Columns.Column>
        <Columns.Column className="content has-text-left">
          <div className="mb-4" dangerouslySetInnerHTML={{ __html: msg.isBeingTypewritten ? displayResponse : msg.content }}></div>
          {showVideos && msg.videos && msg.videos.length > 0 && msg.videos.map((item) => { 
            return (
              <YouTubeVideo url={item} />
            )
          })}
          {msg.answerOptions && msg.answerOptions.length > 0 ? (
            <QuizOptions options={msg.answerOptions} chosenAnswer={msg.chosenAnswer} isCorrect={msg.answer === msg.chosenAnswer} />
          ) : ''}
          {msg.continuations && msg.continuations.length > 0 ? (
            <ContinuationOptions options={msg.continuations} />
          ) : ''}
        </Columns.Column>
        <Columns.Column size="1" className={msg.author === 'user' ? '' : 'is-invisible'}><div className='avatar avatar-user'>Me</div></Columns.Column>
      </Columns>
      </>
    )
  }
  
  
  return (
    <>
    <div className="bookshelf-container">
      <img src="/bookshelf1.png" className="bookshelf is-flex is-flex-direction-row is-align-content-center is-align-items-center" />
      <NavbarActions />
    </div>
    <div className="stories-app" style={{  }}>
      <Container className="main">
        {messages.length > 0 && messages.map((msg) => {
          return (
            <StorySection msg={msg} />
            )
          })}
      </Container>
    </div>
    </>
  )
}

